
$('.open_menu').on('click', function (e) {
    e.preventDefault();
    $('.menu').removeClass('invisible');
    $('.container').addClass('invisible');
});

$('.close_menu').on('click', function (e) {
    e.preventDefault();
    $('.menu').addClass('invisible');
    $('.container').removeClass('invisible');
});

$('.toggle-wrapper h4').on('click', function (e) {
    e.preventDefault();
    if ($(this).parent().hasClass('toggle-open')) {
        $(this).parent().addClass('toggle-closed');
        $(this).parent().removeClass('toggle-open');
    }
    else {
        $(this).parent().addClass('toggle-open');
        $(this).parent().removeClass('toggle-closed');
    }
});

$('#add-department').on('click', function (e) {
    var input = $("<input style='display: none'>").attr("name", "add").val("true");
    $('form[name=department_list]').append(input);
    $('form[name=department_list]').submit();
});


$('#angebote_der_dgp_toggle').on('click', function (e) {
    $('#angebote_der_dgp_toggle_text').css('display', 'block');
    $('#angebote_der_dgp_toggle').css('display', 'none');
})


$('table#institution_benefit tr td:first-child input').on('click', function (e) {
    $id = $(this).attr('id');
    $row = $("[id$='" + $id + "']");

    if ($(this).is(':checked')) {
        $.each($row, function (key, value) {
            $(value).prop('checked', true).attr('checked', 'checked');
            console.log(value);
        });
    }
    else {
        $.each($row, function (key, value) {
            $(value).prop('checked', false).removeAttr('checked');
            console.log(value);
        });
    }
});
$('table#institution_benefit tr:not(:first-child) input').on('click', function (e) {

    parent = $(this).parent().parent();
    i = 0;
    all_rows = parent.find('td:first-child input');

    if ($(this).is(':checked') == false) {
        all_rows.prop('checked', false).removeAttr('checked');
        $(this).prop('checked', false).removeAttr('checked');
    }

    else {
        $(this).prop('checked', true).attr('checked', 'checked');

        sibling = parent.find('td:not(:first-child) input');
        sibling.each(function () {
            if ($(this).prop('checked') == true) {

            }
            else {
                i++;
            }
        })
        if (i == 0) {
            all_rows.prop('checked', true).attr('checked', 'checked');
        }
    }

});

/* autocompletion jquery im sorry*/

/* $('#institution_city').on('keyup', function () {
    var input = $(this).val();
    $('.behind_cursor').text("");
    if (input.length >= 2) {
        $.ajax({
            type: "POST",
            url: "/autocomplete",
            data: {"input": input},
            success: function(data) {
                console.log(data);
                if (data[0]) {
                    $('.behind_cursor').text(data[0]["label"]);
                    $('#institution_city').css("border", "solid 1px #00af9b");
                }
                else {
                    $('.behind_cursor').text("");
                    $('#institution_city').css("border", "solid 1px red");
                }
            }
        });
    }
    else {
        $('.behind_cursor').text("");
        $('#institution_city').css("border", "solid 1px #00af9b");
    }

}); */

$(function () {
    var cities = [];
    var city = $('#institution_city');
    //var city = $('#city');
    var input = city.val();
    city.on('keyup', function () {
        $('.xdsoft_autocomplete_dropdown').css('bottom', 'auto');
    });

    $.ajax({
        type: "POST",
        url: "/autocomplete",
        data: { "input": input },
        success: function (data) {
            cities = data;
            //console.log(cities);
            city.autocomplete({
                source: [cities],
                minLength: 2,
            });
        }
    });


});


function autocomplete() {
    var cities = [];
    var city = $('#city');
    var input = city.val();
    $('.xdsoft_autocomplete_dropdown').css('width', '300px');
    $('.xdsoft_autocomplete').css('width', '300px');
    city.css('width', '300px');
    city.on('keyup', function () {
        console.log(input);
    });

    $.ajax({
        type: "POST",
        url: "/autocomplete",
        data: { "input": input },
        success: function (data) {
            cities = data;
            //console.log(cities);
            city.autocomplete({
                source: [cities],
                minLength: 2,
            });
        }
    });

}

window.autocomplete = autocomplete;

/* activate offer */

function activateOffer($id, $name) {
    el = document.querySelector('span[onclick="activateOffer(\'' + $id +'\', \'' + $name +'\')"]');

    $.ajax({
        type: "POST",
        url: "/activateOffer",
        data: { "id": $id },
        success: function (data) {
            if (data == 1) {
                    el.setAttribute('class', 'active_offer');
                    el.innerHTML = 'VERÖFFENTLICHT';
                    
                    el2 = document.querySelector(".do_in_of"+$id);
                    el2.setAttribute('class', 'underline down_active_offer do_ac_of'+$id);
                    el2.innerHTML = "offline schalten";
            }
            else {
                //Vorab abfrage und bei Bestätigung umsetzen
                var bestaetigung = window.confirm('Möchten Sie das Angebot ' +$name+ ' wirklich schon vorher offline nehmen?');
                if(bestaetigung) {
                    el.setAttribute('class', 'inactive_offer');
                    el.innerHTML = 'NICHT VERÖFFENTLICHT';

                    el2 = document.querySelector('.do_ac_of'+$id);
                    el2.setAttribute('class', 'underline down_inactive_offer do_in_of'+$id);
                    el2.innerHTML = "veröffentlichen";
                }
                //el.setAttribute('class', 'inactive_offer');
                //el.innerHTML = 'NICHT VERÖFFENTLICHT';
            }
        }
    });

}

window.activateOffer = activateOffer;


/*Dropdown JS Vanilla*/

window.addEventListener("load", function (event) {
    var drops = document.getElementsByClassName("dropdownDesign");
    var bodyId = document.body.id;
    //prevent double dropdowns in Börse
    //console.log("not Börse") 
    if (bodyId != 2) {
             
        for (var i = drops.length - 1; i >= 0; i--) {
            dropdown(drops[i]);
        }
    }
});


function dropdown(element) {
    var select = element;
    var options = element.children;


    selectClass = select.getAttribute('class');
    selectClass = selectClass.replace('dropdownDesign', '');

    var ul = document.createElement('ul');
    ul.setAttribute("onclick", "f(this)");
    ul.setAttribute("class", selectClass + " dropdown");
    ul.setAttribute('tabindex', 0);
    //ul.classList.add(selectClass.toString());

    var first_li = document.createElement('li');
    first_li.classList.add("selected_el");
    first_li.innerHTML = options[0].innerHTML;
    ul.appendChild(first_li);

    var div = document.createElement('div');
    div.classList.add('list_box');
    ul.appendChild(div)

    for (var i = 1; i <= options.length - 1; i++) {
        var li = document.createElement('li');
        li.innerHTML = options[i].innerHTML;
        writeAttributes(options[i], li);
        li.setAttribute("onclick", "dropdownClick(this)");
        div.appendChild(li);
        if (options[i].hasAttribute("selected")) {
            first_li.innerHTML = options[i].innerHTML;
        }
    }
    select.setAttribute('style', 'display: none');
    select.parentElement.insertBefore(ul, select);

}
window.dropdown = dropdown;

$(document).click(function (e) {
    var container = $(".dropdown.opened");
    if (container.has(e.target).length === 0) {
        container.each(function () {
            $(this).removeClass('opened');
        })
    }
    else {
    }
});

function f(element) {
    if (element.classList.contains("opened")) {
        element.classList.remove("opened");
        element.childNodes[1].setAttribute('style', 'display: none');
    }
    else {
        var opened = document.getElementsByClassName("dropdown");
        for (var i = 1; i <= opened.length - 1; i++) {
            opened[i].classList.remove("opened");
        }
        element.classList.add("opened");
        element.childNodes[1].setAttribute('style', 'display: block');
    }
}

window.f = f;


function dropdownClick(element) {
    disabledEventPropagation(element);
    var ul = element.parentNode.parentNode;
    var selected_el = element.parentNode.parentNode.children[0];
    var value = element.getAttribute("value");
    var select = element.parentNode.parentNode.parentNode.getElementsByTagName("select")[0];
    console.log(select)
    var event = new Event('change');
    select.dispatchEvent(event)
    selected_el.innerHTML = element.innerHTML;
    ul.classList.remove('opened');
    select.value = value;
    //console.log(select.value);
    var date = select.parentNode.parentNode;
    $date = $(date);
    if ($date.hasClass('dateTypeForm')) {
        dropdownCheckAllClicked($date);
    }
    element.parentNode.setAttribute('style', 'display: none');
}

window.dropdownClick = dropdownClick;

function dropdownCheckAllClicked($date) {
    $select = $date.find('select');
    $i = 0;
    $select.each(function (index) {
        if ($(this).val() != '') {
            $i += 1;
        }
    });
    if ($i == 3) {
        $input = $date.find('input').first();
        $input.prop("checked", true);
        $input.attr("checked", "checked");
    }
}


//overwrites every attribute from element to newelement
function writeAttributes(element, newElement) {
    for (var i = 0, atts = element.attributes, n = atts.length, arr = []; i < n; i++) {
        newElement.setAttribute(atts[i].nodeName, atts[i].nodeValue);
    }
    return 1;
}


function disabledEventPropagation(event) {
    if (event.stopPropagation) {
        event.stopPropagation();
    }
    else if (window.event) {
        window.event.cancelBubble = true;
    }
}

/* Checkbox and Radio Button Vanilla JS */

function checkboxAndRadioDesign() {
    var elements = document.querySelectorAll("input[type=radio], input[type=checkbox]");
    for (var i = elements.length - 1; i >= 0; i--) {
        //console.log(elements[i])
        var element_id = elements[i].getAttribute('id');
        var label = document.querySelector("label[for=" + element_id + "]");
        if (elements[i].hasAttribute('class')) {
            var cl = elements[i].getAttribute('class');
            label.setAttribute('class', cl);
        }
        if (label.getElementsByClassName("checkbox-radio-span").length == 0) {
            text = document.createTextNode(label.innerHTML);
            label.innerHTML = "<span class='checkbox-radio-span'></span>" + label.innerHTML;
            label.setAttribute('tabindex', 0)
            //label.parentNode.insertBefore(text, label.nextSibling);
        }
    }
}

/* Modified checkboxAndRadioDesign() for benefits table as there is no label */

function checkboxTableDesign() {
    var elements = document.querySelectorAll("input[type=checkbox]");
    for (var i = elements.length - 1; i >= 0; i--) {
        if (elements[i].querySelector("span") == null) {
            var label = document.createElement("label");
            label.setAttribute("for", elements[i].getAttribute('id'));
            var span = document.createElement("span");
            span.setAttribute('class', 'checkbox-radio-span');
            span.setAttribute('tabindex', 0);
            label.appendChild(span);
            elements[i].parentNode.appendChild(label);
        }
    }
}

window.checkboxAndRadioDesign = checkboxAndRadioDesign;
window.checkboxTableDesign = checkboxTableDesign;

window.addEventListener("load", function (event) {
    if (document.querySelectorAll("table#institution_benefit").length > 0) {
        checkboxTableDesign();
    }
    else {
        checkboxAndRadioDesign();
    }
});
//window.onload = checkboxTableDesign;

function forgotPassword() {
    //check valid email
    var email = document.getElementById("username");
    window.location.href = '/fuer_partner/reset/passwort?email=' + email.value;
}

window.forgotPassword = forgotPassword;

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

window.validateEmail = validateEmail;

function scrollToTop() {
    var half_column = document.querySelector("#content .half_column:first-child");
    half_column.scrollTop = 0;
}

window.scrollToTop = scrollToTop;


$.fn.extend({
    trackChanges: function () {
        $(":input", this).change(function () {
            $(this.form).data("changed", true);
        });
    }
    ,
    isChanged: function () {
        return this.data("changed");
    }
});

$(".detectFormChanges").trackChanges();

// Warning
$(window).on('beforeunload', function () {
    if ($(".detectFormChanges").isChanged() == true) {
        return "Any changes will be lost";
    }
});

// Form Submit
$(document).on("submit", "form", function (event) {
    //if (!window.location.href.includes('registrierung/institutionreg'))
    $(window).off('beforeunload');
});

function movenextlvl(){
    window.location.href="https://zukunft.pneumologie.de/f%C3%BCr_partner/registrierung/institutionreg?part=2";
}

function closeFlash() {
    $('.flash-success').addClass('invisible');
    $('.flash-warning').addClass('invisible');
}
window.closeFlash = closeFlash;



//remove touch on hover for mobile devices

function hasTouch() {
    return 'ontouchstart' in document.documentElement
        || navigator.maxTouchPoints > 0
        || navigator.msMaxTouchPoints > 0;
}

if (hasTouch()) { // remove all :hover stylesheets
    try { // prevent exception on browsers not supporting DOM styleSheets properly
        for (var si in document.styleSheets) {
            var styleSheet = document.styleSheets[si];
            if (!styleSheet.rules) continue;

            for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
                if (!styleSheet.rules[ri].selectorText) continue;

                if (styleSheet.rules[ri].selectorText.match(':hover')) {
                    styleSheet.deleteRule(ri);
                }
            }
        }
    } catch (ex) { }
}

$("input + label").keydown(function (e) {
    //check if the pressed key is Enter or Spacebar
    //then trigger the click() on the inner span to toggle the checkbox
    if (e.keyCode == 13 || e.keyCode == 32) {
        $('span', this).click();
    }
    //check if the pressed key is not TAB, then cancel the keydown
    //We won't cancel the TAB because it helps switch
    //the focus to the next element.
    if (e.keyCode != 9) return false;
});
$("#edit_offer_startFromCheckbox").on("click", false);


$(document).ready(function () {
    if ($(window).width() < 768) {
        jQuery.each($(".page_content img"), function (i, val) {
            console.log(val.src);
            var title = val.title;
            var image;
            var li;

            image = val.src;
            li = image.lastIndexOf(".");
            image = image.substr(0, li) + "_mobile";
            image = image + val.src.substr(val.src.length - 4, 4);

            console.log(image);

            if (doesFileExist(image)) {
                val.src = image
            } else
                console.log("No mobile image available");
            $(this).attr("alt", i)
        })
    }

    $('#institution_submit').click(function () {
        $("input[required='required']").each(function () {
            if ($(this).val() == '') {
                $(this).css('border', 'solid 1px #ED193A');
            }
        });
    });

})

function doesFileExist(urlToFile) {
    var xhr = new XMLHttpRequest;
    xhr.open("HEAD", urlToFile, false);
    xhr.send();
    if (xhr.status == "404") {
        console.log("File doesn't exist");
        return false
    } else {
        console.log("File exists");
        return true
    }
}

/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('../css/main_fe.css');
require('../css/frontend.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
var $ = require('jquery');
global.$ = global.jQuery = $;

require('jquery-ui');
require('jquery-autocomplete');
require('./main');
require('./viewport-min-width');
require('./ckeditor')
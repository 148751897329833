require('../css/ckeditor.scss');

CKEDITOR.editorConfig = function( config ) {
    config.extraPlugins = 'soft-hyphen-shortcut-key';
    config.contentsCss = '/build/ckeditor.css';
};

CKEDITOR.on( 'instanceReady', function( evt ) {
    evt.editor.dataProcessor.htmlFilter.addRules( {
        elements: {
            a: function(el) {
                el.addClass('inline_anchor');
            },
            ul: function(el) {
                el.addClass('colored_list');
            },
            ol: function(el) {
                el.addClass('colored_list');
            },
        }
    });
});


CKEDITOR.plugins.add('soft-hyphen-shortcut-key', {
    init: function (editor) {
        var shortcutKeys = CKEDITOR.CTRL + CKEDITOR.SHIFT + 189;

        editor.addCommand('insertSoftHyphen', {
            exec: function (editor, data) {
                editor.insertHtml('&shy;');
            }
        });

        editor.keystrokeHandler.keystrokes[shortcutKeys] = 'insertSoftHyphen';
    }
});

CKEDITOR.plugins.add('institution_link', {
    init: function (editor) {
        CKEDITOR.on( 'dialogDefinition', function( ev ) {
            // Take the dialog name and its definition from the event data.
            var dialogName = ev.data.name;
            var dialogDefinition = ev.data.definition;

            // Check if the definition is from the dialog window you are interested in (the "Link" dialog window).
            if ( dialogName == 'link' ) {
                // Get a reference to the "Link Info" tab.
                var infoTab = dialogDefinition.getContents( 'info' );

                // Remove the 'Target' and 'Advanced' tabs from the 'Link' dialog.
                dialogDefinition.removeContents( 'target' );
                dialogDefinition.removeContents( 'advanced' );

                // Set the default value for the URL field.
                var urlField = infoTab.get( 'url' );
                urlField[ 'default' ] = 'www.example.com';

                // Remove the unused field.
                infoTab.remove( 'linkType' );
                infoTab.remove( 'protocol');
            }
        });
    }
})
/*
CKEDITOR.plugins.add('institution_link', {
    init: function (editor) {
        CKEDITOR.on( 'dialogDefinition', function( ev ) {
            // Take the dialog name and its definition from the event data.
            var dialogName = ev.data.name;
            var dialogDefinition = ev.data.definition;

            // Check if the definition is from the dialog window you are interested in (the "Link" dialog window).
            if ( dialogName == 'link' ) {
                // Get a reference to the "Link Info" tab.
                var infoTab = dialogDefinition.getContents( 'info' );
                var informationTab = dialogDefinition.getContents('target');
                var targetField = informationTab.get('linkTargetType');
                targetField['default'] = '_blank';

                // Remove the 'Target' and 'Advanced' tabs from the 'Link' dialog.
                dialogDefinition.removeContents( 'target' );
                dialogDefinition.removeContents( 'advanced' );

                // Set the default value for the URL field.
                var urlField = infoTab.get( 'url' );
                urlField[ 'default' ] = 'www.example.com';

                var textField = infoTab.get('linkDisplayText');
                textField[ 'label' ] = 'Text';

                // Remove the unused field.
                infoTab.get( 'linkType' ).style = 'display: none';
                infoTab.remove( 'protocol');
            }
        });
    }
})*/